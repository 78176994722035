export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGOUT = 'USER_LOGOUT';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const USER_REGISTER = 'USER_REGISTER';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const RESET_PASSWORD_TOKEN_VALIDATION = 'RESET_PASSWORD_TOKEN_VALIDATION';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SOCIAL_LOGIN = 'SOCIAL_LOGIN';
export const RESET_ACCOUNT_PASSWORD = 'RESET_ACCOUNT_PASSWORD';
export const CHANGE_AVATAR = 'CHANGE_AVATAR';
export const SAVE_USER_ROLE_AND_DOB = 'SAVE_USER_ROLE_AND_DOB';
export const GET_TIMEZONE = 'GET_TIMEZONE';
export const SET_TIMEZONE = 'SET_TIMEZONE';

export const createLoginAction = (payload) => ({
  type: USER_LOGIN,
  payload,
});

export const forgotPasswordAction = (payload) => ({
  type: FORGOT_PASSWORD,
  payload,
});

export const createRegisterAction = (payload) => ({
  type: USER_REGISTER,
  payload,
});

export const createConfirmAccountAction = (payload) => ({
  type: CONFIRM_ACCOUNT,
  payload,
});

export const resetPasswordTokenValidationAction = (payload) => ({
  type: RESET_PASSWORD_TOKEN_VALIDATION,
  payload,
});

export const resetPasswordAction = (payload) => ({
  type: RESET_PASSWORD,
  payload,
});

export const socialLoginAction = (payload) => ({
  type: SOCIAL_LOGIN,
  payload,
});

export const logoutAction = () => ({
  type: USER_LOGOUT,
});

export const resetAccountPasswordAction = (payload) => ({
  type: RESET_ACCOUNT_PASSWORD,
  payload,
});

export const changeAvatarAction = (payload) => ({
  type: CHANGE_AVATAR,
  payload,
});

export const saveUserRoleAndDobAction = (payload) => ({
  type: SAVE_USER_ROLE_AND_DOB,
  payload,
});

export const getTimezoneAction = () => ({
  type: GET_TIMEZONE,
});

export const setTimezoneAction = (payload) => ({
  type: SET_TIMEZONE,
  payload,
});
