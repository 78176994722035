export const themeVariables = {
  // colors
  colors: {
    paleGray: '#F9FCFD',
    mainPale: '#00b6b7',
    primaryPale: '#70CDCD',
    primaryLight: '#00E1E3',
    primaryExtraLight: '#E4EFEF',
    secondaryTextOnPrimary: '#A1EAE9',
    accent: '#FF0088',
    primaryText: '#1F3B63',
    secondaryText: '#728196',
    tetriaryText: '#C0CBD7',
    lines: '#E3E7EE',
    backgroundField: '#F5F7FC',
    backgroundFieldHover: '#EBF0F6',
    background: '#F0F3F8',
    white: '#ffffff',
    dark: '#142d50',
    brightYellow: '#f4ff00',
    avatarBg: '#53c5c5',
  },
};
