const NextI18Next = require('next-i18next').default;

module.exports = new NextI18Next({
  defaultLanguage: 'en',
  otherLanguages: ['en'],
  strictMode: false,
  // localeSubpaths: {
  //     de: 'de'
  // }
});

// how to change language
// <Button
//     variant="contained"
//     color="primary"
//     onClick={() => i18n.changeLanguage(i18n.language === 'en' ? 'de' : 'en')}
// >
//     {t('changelocale')}
// </Button>
